import type { HomePageQueryResult } from "../../sanity.types";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import PortableText from "react-portable-text";

export const FAQ = (props: { faqs: HomePageQueryResult["faqs"] }) => {
  if (!props?.faqs) return <></>;
  return (
    <div className="container  mx-auto">
      <h2 className="uppercase lg:text-center my-8 lg:my-16 tracking-wider text-3xl font-bold text-gray">
        Things to Know
        <span className="text-orange"> Before You Go.</span>
      </h2>
      <Accordion
        onValueChange={(question) => {
          (window as any)?.umami?.track("read_faq", {
            question,
          });
        }}
        className="mx-auto lg:w-2/3"
        type="single"
        collapsible
      >
        {props?.faqs?.map(({ question, answer }, index) => (
          <AccordionItem
            data-aos="fade-in"
            data-aos-delay={(index + 1) * 200}
            key={question}
            value={question ?? index.toString()}
          >
            <AccordionTrigger className="text-xl text-left text-green data-[state=open]:text-orange">
              {question}
            </AccordionTrigger>
            <AccordionContent className="prose">
              <PortableText content={answer} />
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};
